<script setup lang="ts">
interface IProps {
    blok: LandingImageCarouselBlokInterface;
}
const props = defineProps<IProps>();

const breakpoints = computed(() => {
    const result: EsCarouselBreakpointsInterface = {};

    if (props.blok.countImageMd) {
        result.md = {
            numVisible: parseInt(props.blok.countImageMd, 10),
        };
    }

    if (props.blok.countImageLg) {
        result.lg = {
            numVisible: parseInt(props.blok.countImageLg, 10),
        };
    }

    return result;
});

// repeat the image list four times to account for the fact that the circular autoplay behavior
// doesn't smoothly go page by page forever, it gets to the end and then rushes all the way
// back to the beginning - this at least minimizes the frequency of that behavior being seen
const items = computed(() => [...props.blok.images, ...props.blok.images, ...props.blok.images, ...props.blok.images]);
</script>

<template>
    <es-carousel
        v-if="blok.images?.length"
        v-editable="blok"
        :auto-play="blok.autoplay"
        :auto-play-interval="blok.autoPlaySpeed ? parseInt(blok.autoPlaySpeed, 10) : undefined"
        :breakpoints="breakpoints"
        :items="items"
        :num-visible="blok.countImageSm ? parseInt(blok.countImageSm, 10) : undefined"
        :show-arrows="blok.navigationButtons"
        :show-dots="false">
        <template #item="{ item }">
            <div
                class="align-items-center d-flex h-100 px-100"
                :class="{ 'py-100': blok.additionalVerticalSpacing }">
                <es-card
                    class="align-items-center d-flex h-100 justify-content-center px-100 px-sm-200 px-lg-100 py-200">
                    <storyblok-image
                        :alt="item.alt"
                        :src="item.filename"
                        sizes="lg:400px xxxl:500px"
                        class="w-100" />
                </es-card>
            </div>
        </template>
    </es-carousel>
</template>
